<!--
 * @Description:
 * @Author: heqi
 * @Date: 2022-07-20 11:11:52
 * @LastEditTime: 2022-10-08 13:51:44
 * @LastEditors: heqi
 * @FilePath: \mfzj\src\views\layout\work\okr\myokr\component\MyokrTop.vue
-->
<template>
  <div class="my-card myokr-top">
    <span><span v-if="targetType && route.name === 'myokr'" class="bg-active-color target-type">{{targetType}}</span>我的OKR-{{department}}</span>
    <div class="myokr-top-right">
      <PickDate
        @confirmSelDate="confirmSelDate"
        :choiceModel="dateSelType"
        :btnStatus="showBtns.includes(route.name) ? false : true"
        :historySelect="route.name === 'okrHistory'"
      ></PickDate>
      <span v-if="route.path !== '/layout/okr/okrHistory'" class="active-text-color cursor-pointer" @click="toOkrHistory">历史目标</span>
    </div>
  </div>
  <div class="cover-line"></div>
</template>

<script>
import { toRefs, reactive } from '@vue/reactivity'
import { computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'

export default {
  emits: ['confirmSelDate'],
  props: {
    department: {
      type: String,
      default: ''
    },
    dateSelType: {
      type: String,
      default: 'simple'
    },
    targetType: {
      type: String,
      default: ''
    }
  },
  setup (props, { emit }) {
    const state = reactive({
      department: computed(() => props.department),
      depId: computed(() => route.params.id),
      dateSelType: computed(() => props.dateSelType),
      showBtns: ['myokr', 'okrHistory', 'okr-add'],
      targetType: computed(() => props.targetType)
    })

    const router = useRouter()
    const route = useRoute()

    // 选择日期
    const confirmSelDate = (date) => {
      emit('confirmSelDate', date)
    }

    const toOkrHistory = () => {
      router.push({ name: 'okrHistory', query: { department: state.department, depId: state.depId } })
    }

    return {
      ...toRefs(state),
      confirmSelDate,
      toOkrHistory,
      route
    }
  }
}
</script>

<style lang="less" scoped>
.myokr-top {
  position: relative;
  display: flex;
  justify-content: space-between;
  height: 46px;
  line-height: 18px;
  font-weight: 400;
  font-size: 14px;
  color: @default-text-color;
  padding: 13px 24px 15px 24px;
  z-index: 2;
}
.myokr-top-right {
  display: flex;
}
.myokr-top-right > span{
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  margin-left: 16px;
}
.target-type {
  border-radius: 10px;
  padding: 0 4px;
  margin-right: 5px;
  color: @colorWhite;
}
</style>
