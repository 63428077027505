<template>
  <MyokrTop :department="department" @confirmSelDate="confirmSelDate" ref="okrTopRef" :targetType="okrData.ork_type"></MyokrTop>
  <div :class="['myokr-bottom', okrData.id ? '' : 'myokr-empty']"
    v-loading="loading"
    element-loading-text="Loading..."
    element-loading-svg-view-box="-10, -10, 50, 50"
    >
    <template v-if="route.name === 'okr-add'">
      <router-view name="okrContent"></router-view>
    </template>
    <template v-else-if="!okrData.key_results?.length">
      <EmptyData image="no-okr-target" description="暂无目标" size="170"></EmptyData>
      <el-button @click.stop.prevent="addTarget" :class="['add-target', evalute ? 'is-evalute' : '']">
        <img src="@/assets/img/work/wor-okr-plus-white.svg">创建目标
        <p class="evalute-tip" v-if="preData?.id">你有历史目标尚未评价，<span class="active-text-color cursor-pointer" @click.stop="toEvalute()">去评价</span></p>
      </el-button>
    </template>
    <template v-else>
      <OkrTemplate :date="{ year: selDate.selYear, month: selDate.selCycle }" :cycleId="selDate.selID"  :okrData="okrData" @revocation="uploadOkr" @uploadOkr="uploadOkr" @deleteOkr="deleteOkr"></OkrTemplate>
    </template>
  </div>

  <OkrDialog
    :showDialogVisible="centerDialogVisible"
    :dialogContent="dialogContent"
    @confirm="confirm"
    @cancel="cancel">
  </OkrDialog>
</template>

<script>
import { reactive, toRefs, computed } from 'vue'
import { useRouter, useRoute, onBeforeRouteLeave } from 'vue-router'
import { useStore } from 'vuex'
import MyokrTop from './component/MyokrTop.vue'
import OkrDialog from '@/views/layout/work/okr/component/OkrDialog.vue'
import OkrTemplate from './component/OkrTemplate.vue'
import { getOkrInfo } from '@/apis/okr.js'
import eventBus from '@/utils/eventBus.js'
import { dialogMsg } from '@/utils/dialogMsg.js'

export default {
  components: {
    MyokrTop,
    OkrDialog,
    OkrTemplate
  },
  setup (props, { emit }) {
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const state = reactive({
      loading: false,
      department: computed(() => route.params.depName),
      depId: computed(() => route.params.id),
      centerDialogVisible: false,
      dialogContent: '',
      okrTopRef: null,
      selDate: {},
      okr: computed(() => store.state.okr),
      okrData: {}, // 当前okr数据
      preData: '',
      evalute: false
    })

    onBeforeRouteLeave((to, from, next) => {
      if (to.name === 'allTarget' || to.name === 'targetAligned' || to.name === 'okrHistory') {
        to.query.date = state.selDate
        to.query.originDepId = state.depId
      }
      next()
    })

    // 创建目标
    const addTarget = (e) => {
      if (state.selDate.isYearCycle) {
        if (process.env.VUE_APP_COMPANY_NAME !== state.department) return dialogMsg('error', '只有最大部门可以创建年度目标')
      }
      if (e.target.className === 'evalute-tip') return false
      state.centerDialogVisible = true
      state.dialogContent = `确认创建周期为${state.selDate.selYear}年${state.selDate.selCycle || ''}的目标吗?`
    }

    // 确认创建okr
    const confirm = () => {
      state.centerDialogVisible = false
      router.push({ name: 'okr-add', query: { year: state.selDate.selYear, month: state.selDate.selCycle, cycleId: state.selDate.selID } })
    }

    // 取消创建okr
    const cancel = () => {
      state.centerDialogVisible = false
    }

    // 选择日期
    const confirmSelDate = (date) => {
      if (route.name === 'okr-add') {
        state.selDate = date
        getOkrList()
        router.push({ name: 'okr-add', query: { year: state.selDate.selYear, month: state.selDate.selCycle, cycleId: state.selDate.selID } })
        return
      }
      if (route.name !== 'myokr') return
      // 跳转页面
      router.push({ name: 'myokr', params: { id: state.depId, depName: route.params.depName } })
      state.selDate = date // selID , selType, queryType
      getOkrList()
    }

    const getOkrList = (queryType) => {
      state.loading = true
      state.okrData = {}

      const data = {
        cycle_id: state.selDate.selID,
        dept_id: state.depId,
        id: undefined
      }
      if (queryType === 'evalute') {
        data.cycle_id = state.preData.cycle_id
        data.dept_id = state.preData.dept_id
        data.id = state.preData.id
      }
      if (!data.cycle_id || !data.dept_id) return
      getOkrInfo(data).then(({ code, data, msg }) => {
        if (code === 0) {
          if (data.pre_okr?.id) {
            // 含有上一个okr未评价数据，且本月okr未创建
            state.preData = data.pre_okr
            return false
          }
          if (data.id) {
            // 置空上一个okr记录
            state.preData = {}
            data.queryType = state.selDate.queryType
            data.key_results.forEach(item => {
              item.disabled = true
            })
            state.okrData = data

            if (route.name === 'okr-add') {
              router.replace({ name: 'myokr', params: { id: state.depId, depName: route.params.depName }, query: { ignoreQuit: 1 } })
            }
          }
        }
      }).finally(() => {
        setTimeout(() => {
          state.loading = false
          store.commit('closeLoading')
        }, 200)
      })
    }

    const toEvalute = () => {
      getOkrList('evalute')
    }

    // 更新okr
    const deleteOkr = () => {
      (state.okrData.status !== 1) && eventBus.$emit('uploadCycle', state.selDate.selID)
      state.okrData = {}
    }
    const uploadOkr = () => {
      getOkrList()
    }

    eventBus.$on('getOkrList', getOkrList)
    eventBus.$on('saveDraft', getOkrList)
    return {
      ...toRefs(state),
      addTarget,
      confirm,
      cancel,
      confirmSelDate,
      route,
      router,
      toEvalute,
      uploadOkr,
      deleteOkr
    }
  }
}
</script>

<style lang="less" scoped>

.myokr-bottom {
  width: 955px;
  height: auto;
  margin-top: 8px;
}
.myokr-empty {
  background: #FFFFFF;
  border: 1px solid #F1F1F1;
  border-radius: 6px;
  overflow: hidden;
}
:deep(.el-empty) {
  width: auto !important;
  padding-top: 130px;
  padding-bottom: 0;
  margin-bottom: 60px;
  .el-empty__description {
    margin-top: 0;
    font-size: 14px;
    color: #999999;
  }
  .el-empty__bottom {
    margin-top: 0;
    height: 0;
  }
}
.myokr-bottom .add-target {
  position: relative;
  display: block;
  margin: 0 auto;
  width: 240px;
  height: 44px;
  background: @active-text-color;
  border-radius: 33px;
  padding: 13px 48px;
  gap: 10px;
  font-size: 14px;
  line-height: 18px;
  color: #FFF;
  border: none;
  margin-bottom: 197px;
  img {
    width: 16px;
    height: 16px;
    vertical-align: top;
    margin-right: 8px;
    svg {
      width: 16px;
      height: 16px;
    }
  }
}
.evalute-tip {
  position: absolute;
  bottom: -42px;
  left: 50%;
  transform: translateX(-50%);
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #333333;
}
.is-evalute {
  margin-bottom: 0 !important;
}
</style>
