<!--
 * @Description:
 * @Author: heqi
 * @Date: 2022-07-12 13:45:00
 * @LastEditTime: 2022-07-13 09:41:07
 * @LastEditors: heqi
 * @FilePath: \sns_h5d:\hq\mfzj\src\views\layout\work\okr\component\okrDialog.vue
-->
<template>
  <div class="my-okr-dialog">
    <el-dialog
      v-model="centerDialogVisible"
      :width="width"
      center
      top="240px"
      :close-on-click-modal="false"
    >
      <div>{{dialogContent}}</div>
      <template #footer>
        <span class="dialog-footer">
          <el-button class="confirm-btn" @click="confirm">确定</el-button>
          <el-button class="cancel-btn" @click="cancel">取消</el-button>
        </span>
      </template>
    </el-dialog>
  </div>

</template>

<script>
import { computed, reactive, toRefs, watch } from 'vue'
export default {
  name: 'OkrDialog',
  props: {
    dialogContent: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: '320px'
    },
    showDialogVisible: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit, context }) {
    const state = reactive({
      dialogContent: computed(() => props.dialogContent),
      width: computed(() => props.width),
      showDialogVisible: computed(() => props.showDialogVisible),
      centerDialogVisible: false
    })

    const confirm = () => {
      emit('confirm')
    }

    const cancel = () => {
      emit('cancel')
    }

    watch(() => state.showDialogVisible, (newValue) => {
      state.centerDialogVisible = newValue
    }, { immediate: true })

    return { ...toRefs(state), confirm, cancel }
  }
}
</script>

<style lang="less">
.my-okr-dialog {
  .el-dialog  {
    padding: 36px 0 28px;
    background: #FFF;
    border: 1px solid #F1F1F1;
    box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    .el-dialog__header{
      display: none
    }
    .el-dialog__body,
    .el-dialog__footer {
      padding: 0;
    }
    .el-dialog__body {
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
      text-align: center;
      color: #333333;
    }
    .el-dialog__footer {
      margin-top: 24px;
    }
    .confirm-btn,
    .cancel-btn {
      font-size: 16px;
      background: @active-text-color;
      border-radius: 51px;
      width: 100px;
      height: 32px;
      line-height: 32px;
      min-height: 0px !important;
      color: #FFFFFF;
      border: 0;
      padding: 0;
    }
    .cancel-btn{
      background: @second-text-color
    }
  }
}
</style>
